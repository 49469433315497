<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Asetukset</div>
    <v-card class="mt-2">
      <v-card-title>Stripe</v-card-title>

      <v-card-text>
        <v-container>
          <div v-if="!stripeAccount">
            <h2>Ladataan Stripe-profiilia. Odota hetki...</h2>
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <h3 class="mb-3" v-if="message" v-html="message"></h3>
          <v-progress-circular v-if="redirect" indeterminate color="primary"></v-progress-circular>

          <div v-if="createLink">
            <v-btn class="mr-2 mb-2" color="primary" @click="createStripeAccount"
              >Jatka valtuutuksen tekoa</v-btn
            >
            <v-btn class="mb-2" outlined color="error" @click="$router.push('/settings')"
              >Palaa toistaiseksi asetuksiin</v-btn
            >
            <p>Voit jatkaa valtuutuksen tekoa myöhemminkin, jos päätät nyt palata asetuksiin...</p>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      message: "",
      createLink: false,
      redirect: false,
    };
  },
  created() {
    this.getStripeAccount();
  },

  computed: {
    ...mapState("stripe", ["stripeAccount"]),
  },

  watch: {
    async stripeAccount(val) {
      if (val.details_submitted) {
        this.redirect = true;
        this.message =
          "Kaikki kunnossa ja voit nyt alkaa vastaanottaa maksuja. <br> Odota hetki, niin sinut uudelleen ohjataan takaisin asetuksiin..";
        await this.verifyStripeAccount();

        setTimeout(() => {
          this.$router.push("/settings");
        }, 4000);
      } else {
        this.message =
          "Näyttää siltä, että et ole vielä täydentänyt kaikki tarvittavia tietoja Stripen käyttöönottamiseksi.";
        this.createLink = true;
      }
    },
  },

  methods: {
    ...mapActions("stripe", ["getStripeAccount", "createStripeAccount", "verifyStripeAccount"]),
  },
};
</script>

<style></style>
